import React from "react";
import ComCarousel from "@components/portfolio/shino/ComCarousel";
import { Paintings } from "@constants/portfolio/shino/ConSummary";

class ComSummary extends React.Component {
  state = {
    currentIndex: 0,
  };
  render() {
    console.log(Paintings[this.state.currentIndex].pathBG);
    return (
      <>
        <div
          className="summary__img"
          style={{
            backgroundImage: `url(${
              Paintings[this.state.currentIndex].pathBG
            })`,
          }}
        >
          <ComCarousel
            callback={(currentIndex) =>
              this.setState({ currentIndex: currentIndex })
            }
          />
        </div>
        <div className="summary__img--full">
          <img
            id={Paintings[this.state.currentIndex].id}
            key={this.state.currentIndex}
            alt={Paintings[this.state.currentIndex].description}
            src={Paintings[this.state.currentIndex].path}
          />
        </div>
        <div className="summary__des" style={{ background: "yellow" }}>
          {Paintings[this.state.currentIndex].description}
        </div>
      </>
    );
  }
}
export default ComSummary;
