import React from "react";
import { Redirect } from "react-router-dom";
import AlcCompute from "@components/tools/AlcCompute";
import DiscountCompute from "@components/tools/DiscountCompute";
import About from "@containers/About";
import Home from "@containers/Home";

/*shino作品集start*/
import Shino from "@containers/portfolio/Shino";
import ComSummary from "@components/portfolio/shino/ComSummary";
/*shino作品集end */

const routes = [
  {
    path: "/",
    exact: true,
    render: () => <Redirect to={"/about"} />,
  },
  {
    path: "/about",
    component: About,
    breadcrumbName: "About",
  },
  {
    path: "/mcs/tools",
    component: Home,
    routes: [
      {
        path: "/mcs/tools",
        exact: true,
        render: () => <Redirect to={"/mcs/tools/AlcCompute"} />,
      },
      {
        path: "/mcs/tools/AlcCompute",
        component: AlcCompute,
        breadcrumbName: "AlcCompute",
      },
      {
        path: "/mcs/tools/DiscountCompute",
        component: DiscountCompute,
        breadcrumbName: "DiscountCompute",
      },
      {
        path: "*",
        exact: true,
        render: () => <Redirect to={"/"} />,
      },
    ],
  },
  {
    path: "/portfolio",
    component: Shino,
    routes: [
      {
        path: "/portfolio",
        exact: true,
        render: () => <Redirect to={"/portfolio/shino"} />,
      },
      {
        path: "/portfolio/shino",
        component: ComSummary,
        breadcrumbName: "ComSummary",
      },
      {
        path: "*",
        exact: true,
        render: () => <Redirect to={"/portfolio/shino"} />,
      },
    ],
  },
  {
    path: "*",
    exact: true,
    render: () => <Redirect to={"/"} />,
  },
];

export default routes;
