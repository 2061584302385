import React from "react";
import { Form, InputNumber, Row, Col } from "antd";

class AlcCompute extends React.Component {
  state = {
    AlcPercentHas: 96,
    AlcPercentNeed: 75,
    AlcMLNeed: 500,
  };
  get AlcDensity() {
    return (
      (this.state.AlcPercentHas * 0.8 + (100 - this.state.AlcPercentHas * 1)) /
      100
    );
  }
  get AlcCompute() {
    let data = {
      AlcMLAdd: 0,
      WaterMLAdd: 0,
    };
    data.AlcMLAdd =
      (this.state.AlcPercentNeed * this.state.AlcMLNeed) /
      this.state.AlcPercentHas;
    data.WaterMLAdd = this.state.AlcMLNeed - data.AlcMLAdd;
    return data;
  }
  onValuesChange = (_, values) => {
    this.setState({
      AlcPercentHas: values.AlcPercentHas,
      AlcPercentNeed: values.AlcPercentNeed,
      AlcMLNeed: values.AlcMLNeed,
    });
  };
  result = () => {
    if (this.state.AlcPercentHas > this.state.AlcPercentNeed) {
      return (
        <pre>
          {`添加水(ml): ${this.AlcCompute.WaterMLAdd.toFixed(2)}
添加酒精(ml): ${this.AlcCompute.AlcMLAdd.toFixed(2)}

或

添加水(g): ${this.AlcCompute.WaterMLAdd.toFixed(2)}
添加酒精(g): ${(this.AlcCompute.AlcMLAdd * this.AlcDensity).toFixed(2)}`}
        </pre>
      );
    } else {
      return (
        <pre style={{ color: "#ff4d4f" }}>
          {`添加的酒精濃度要大於想得到的酒精濃度！`}
        </pre>
      );
    }
  };
  render() {
    return (
      <Row>
        <Col span={24}>
          <h1>酒精換算器</h1>
          <Form
            size="large"
            name="basic"
            /* eslint-disable */
            initialValues={{
              ["AlcPercentHas"]: this.state.AlcPercentHas,
              ["AlcPercentNeed"]: this.state.AlcPercentNeed,
              ["AlcMLNeed"]: this.state.AlcMLNeed,
            }}
            /* eslint-enable */
            onValuesChange={this.onValuesChange}
          >
            <Form.Item name="AlcPercentHas" label="添加酒精濃度(%)">
              <InputNumber min={1} max={100} />
            </Form.Item>
            <Form.Item
              name="AlcPercentNeed"
              label="欲得酒精濃度(%)"
              dependencies={["AlcPercentHas"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("AlcPercentHas") > value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "添加的酒精濃度要大於想得到的酒精濃度！"
                    );
                  },
                }),
              ]}
            >
              <InputNumber min={1} max={100} />
            </Form.Item>
            <Form.Item name="AlcMLNeed" label="欲得酒精容量(ml)">
              <InputNumber min={1} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <hr />
          <h1>計算結果</h1>
          {this.result()}
        </Col>
      </Row>
    );
  }
}
export default AlcCompute;
