import React from "react";
import LayoutBasic from "@layouts/LayoutBasic";
import ComAbout from "@components/ComAbout";

const About = () => {
  return (
    <LayoutBasic className="appPage">
      <ComAbout />
    </LayoutBasic>
  );
};
export default About;
