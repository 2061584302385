import React from "react";
import LayoutBasic from "@layouts/portfolio/shino/LayoutBasic";
import { Switch } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import "@styles/portfolio/Shino.sass";

const Shino = (props) => {
  return (
    <LayoutBasic className="appPage shino">
      <Switch>{renderRoutes(props.route.routes)}</Switch>
    </LayoutBasic>
  );
};
export default Shino;
