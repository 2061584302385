import React from "react";
import { Layout } from "antd";
import ComHeader from "@components/ComHeader";
import ComFooter from "@components/ComFooter";
const { Header, Content, Footer } = Layout;

const LayoutBasic = (props) => {
  return (
    <Layout className={props.className}>
      <Layout>
        <Header className="header">
          <ComHeader />
        </Header>
        <Content className="content">
          {props.breadcrumb}
          {props.title}
          <div className="content__item">{props.children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <ComFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};
export default LayoutBasic;
