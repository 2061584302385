import React from "react";
import LayoutBasic from "@layouts/LayoutBasic";
import { Switch } from "react-router-dom";
import { renderRoutes } from "react-router-config";
// import { renderRoutes, matchRoutes } from "react-router-config";
// import { Breadcrumb } from "@components/Breadcrumb";

/* 自定義麵包屑初始位置
const onMatchedRoutes = (matchedRoutes) => {
  return [
    {
      route: {
        path: '/',
        breadcrumbName: 'mcs'
      }
    },
    ...matchedRoutes,
  ];
};
*/
const breadcrumb = (
  /**如果需要麵包屑，要從這傳 */
  /*
  <Breadcrumb
    locationPath={props.location.pathname}
    onMatchedRoutes={onMatchedRoutes}
  />
  */
  <></>
);

const Home = (props) => {
  return (
    <LayoutBasic className="appPage" breadcrumb={breadcrumb}>
      <Switch>{renderRoutes(props.route.routes)}</Switch>
    </LayoutBasic>
  );
};
export default Home;
