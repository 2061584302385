import React from "react";
import "@styles/Tools.sass";
import { Form, Checkbox, InputNumber, Select, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const rules = [{ required: true }];
const { Option } = Select;
class DiscountCompute extends React.Component {
  state = {
    value: "",
    goodsKind: 0,
    goodsList: [],
    isCounting: false,
    discountMulti: [
      {
        disType: "type1",
        isChecked: false,
        rule: 1,
        ruleDis: 1,
      },
      {
        disType: "type2",
        isChecked: false,
        rule: 1,
        ruleDis: 1,
      },
      {
        disType: "type3",
        isChecked: false,
        rule: 1,
        ruleDis: 1,
      },
    ],
  };
  get discountAmount() {
    if (this.goodsTotalAmount) {
      let obj = { sum: this.goodsTotalAmount, des: [] };
      let ratio = 1;
      this.state.discountMulti.forEach((item, index) => {
        if (item.isChecked && this.goodsTotalAmount >= item.rule) {
          obj.des = `${obj.des}符合活動${index + 1}. `;
          item.ruleDis < 10
            ? (ratio = item.ruleDis / 10)
            : (ratio = item.ruleDis / 100);
          switch (item.disType) {
            case "type1":
              obj.sum = obj.sum * ratio >= 1 ? obj.sum * ratio : 1;
              break;
            case "type2":
              obj.sum = obj.sum * ratio >= 1 ? obj.sum * ratio : 1;
              break;
            case "type3":
              obj.sum =
                obj.sum - item.ruleDis >= 0 ? obj.sum - item.ruleDis : 0;
              break;
            default:
              break;
          }
        }
      });
      return obj;
    }
    return false;
  }
  get goodsTotalAmount() {
    if (this.state.isCounting) {
      const reducePlus = this.state.goodsList.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.goodsSum;
        },
        0
      );
      return reducePlus;
    } else return false;
  }
  goodsListforMap = (goods, index) => {
    const goodsElem = (
      <>
        <Form.Item>
          <Form.Item className="goodsGroup__desTxt">
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => {
                this.goodsListRemove(goods.goodsID);
              }}
            />
            &nbsp; 商品{index + 1}：
          </Form.Item>
          <Form.Item rules={rules} className="goodsGroup__select">
            <Select
              value={goods.ruleType}
              onChange={(value) =>
                this.goodsListUpdate(goods.goodsID, "ruleType", value)
              }
              allowClear
            >
              <Option value="None">無優惠</Option>
              <Option value="Type1">買幾件就打幾折</Option>
              <Option value="Type2">第幾件打幾折</Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Col span={24}>
            {goods.ruleType === "None" && (
              <>
                <Col span={24}>
                  <Form.Item className="goodsGroup__desTxt">原價：</Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsPrice}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsPrice", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;元，已購買：
                  </Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsNum}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsNum", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;件。
                  </Form.Item>
                </Col>
                {!!this.state.isCounting && (
                  <Col span={24}>
                    <Form.Item className="goodsGroup__desTxt">
                      <span>沒有任何優惠活動，總價 {goods.goodsSum} 元。</span>
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            {goods.ruleType === "Type1" && (
              <>
                <Col span={24}>
                  <Form.Item className="goodsGroup__desTxt">原價：</Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsPrice}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsPrice", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;元，已購買：
                  </Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsNum}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsNum", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;件。
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item className="goodsGroup__desTxt">買&nbsp;</Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.ruleNum}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "ruleNum", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;件，打&nbsp;
                  </Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.ruleDisRatio}
                      onChange={(value) =>
                        this.goodsListUpdate(
                          goods.goodsID,
                          "ruleDisRatio",
                          value
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;折。
                  </Form.Item>
                </Col>
                {!!this.state.isCounting && (
                  <Col span={24}>
                    <Form.Item className="goodsGroup__desTxt">
                      {goods.goodsNum >= goods.ruleNum ? (
                        <span>符合優惠活動，總價 {goods.goodsSum} 元。</span>
                      ) : (
                        <span style={{ color: "red" }}>
                          不符合優惠活動，總價 {goods.goodsSum} 元。
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            {goods.ruleType === "Type2" && (
              <>
                <Col span={24}>
                  <Form.Item className="goodsGroup__desTxt">原價：</Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsPrice}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsPrice", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;元，已購買：
                  </Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.goodsNum}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "goodsNum", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;件。
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item className="goodsGroup__desTxt">第&nbsp;</Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.ruleNum}
                      onChange={(value) =>
                        this.goodsListUpdate(goods.goodsID, "ruleNum", value)
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;件，打&nbsp;
                  </Form.Item>
                  <Form.Item rules={rules} className="goodsGroup__item">
                    <InputNumber
                      min={0}
                      value={goods.ruleDisRatio}
                      onChange={(value) =>
                        this.goodsListUpdate(
                          goods.goodsID,
                          "ruleDisRatio",
                          value
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item className="goodsGroup__desTxt">
                    &nbsp;折。
                  </Form.Item>
                </Col>
                {!!this.state.isCounting && (
                  <Col span={24}>
                    <Form.Item className="goodsGroup__desTxt">
                      {goods.goodsNum >= goods.ruleNum ? (
                        <span>符合優惠活動，總價 {goods.goodsSum} 元。</span>
                      ) : (
                        <span style={{ color: "red" }}>
                          不符合優惠活動，總價 {goods.goodsSum} 元。
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
          </Col>
        </Form.Item>
      </>
    );
    return (
      <Col key={goods.goodsID} span={24} className="goodsGroup">
        {goodsElem}
      </Col>
    );
  };
  goodsListAdd = () => {
    let newArr = this.state.goodsList;
    let newRid = this.state.goodsKind + 1;
    const newGoods = {
      goodsID: `goods${newRid}`,
      goodsNum: 0,
      goodsPrice: 0,
      goodsSum: 0,
      ruleType: "None",
      ruleNum: 0,
      ruleDisRatio: 0,
    };
    newArr.push(newGoods);
    this.setState({ goodsList: newArr, goodsKind: newRid });
  };
  goodsListUpdate = (target, updateObj, updateValue) => {
    let newArr = this.state.goodsList;
    newArr.forEach((item) => {
      if (item.goodsID === target) item[updateObj] = updateValue;
    });
    this.setState({ goodsList: newArr, isCounting: false });
  };
  goodsListRemove = (target) => {
    let newArr = this.state.goodsList;
    newArr = newArr.filter((item) => {
      return item.goodsID !== target;
    });
    this.setState({ goodsList: newArr, isCounting: false });
  };
  goodsSumCount = () => {
    let newArr = this.state.goodsList;
    let ratio = 0;
    newArr.forEach((item) => {
      item.ruleDisRatio < 10
        ? (ratio = item.ruleDisRatio / 10)
        : (ratio = item.ruleDisRatio / 100);
      switch (item.ruleType) {
        case "Type1":
          item.goodsNum >= item.ruleNum
            ? (item.goodsSum = item.goodsPrice * item.goodsNum * ratio)
            : (item.goodsSum = item.goodsPrice * item.goodsNum);
          break;
        case "Type2":
          item.goodsNum >= item.ruleNum
            ? (item.goodsSum =
                item.goodsPrice * (item.goodsNum - 1) + item.goodsPrice * ratio)
            : (item.goodsSum = item.goodsPrice * item.goodsNum);
          break;
        default:
          item.goodsSum = item.goodsPrice * item.goodsNum;
          break;
      }
    });
    this.setState({ goodsList: newArr, isCounting: true });
  };
  goodsDiscountMulti = (target, updateObj, updateValue) => {
    let newArr = this.state.discountMulti;
    newArr.forEach((item) => {
      if (item.disType === target) item[updateObj] = updateValue;
    });
    this.setState({ discountMulti: newArr, isCounting: false });
  };
  render() {
    const goodsListChild = this.state.goodsList.map(this.goodsListforMap);
    return (
      <Row>
        <Col span={24}>
          <h1>折價金額換算器</h1>
          <Form className="my-form">
            <Form.Item>
              <Form.Item>
                <h4>商品清單：</h4>
              </Form.Item>
              {goodsListChild}
            </Form.Item>
            <Form.Item>
              <Button
                type="dashed"
                onClick={this.goodsListAdd}
                style={{ width: "100%" }}
              >
                <PlusOutlined /> 輸入商品
              </Button>
            </Form.Item>
            <Form.Item>
              <Form.Item>
                <h4>複選活動(依序計算)：</h4>
              </Form.Item>

              <Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={(e) =>
                      this.goodsDiscountMulti(
                        "type1",
                        "isChecked",
                        e.target.checked
                      )
                    }
                  >
                    刷卡優惠，活動一
                  </Checkbox>
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">
                  刷卡打&nbsp;
                </Form.Item>
                <Form.Item rules={rules} className="goodsGroup__item">
                  <InputNumber
                    min={1}
                    value={this.state.discountMulti[0].ruleDis}
                    onChange={(value) =>
                      this.goodsDiscountMulti("type1", "ruleDis", value)
                    }
                  />
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">&nbsp;折。</Form.Item>
              </Form.Item>

              <Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={(e) =>
                      this.goodsDiscountMulti(
                        "type2",
                        "isChecked",
                        e.target.checked
                      )
                    }
                  >
                    滿額優惠，活動二
                  </Checkbox>
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">滿&nbsp;</Form.Item>
                <Form.Item rules={rules} className="goodsGroup__item">
                  <InputNumber
                    min={1}
                    value={this.state.discountMulti[1].rule}
                    onChange={(value) =>
                      this.goodsDiscountMulti("type2", "rule", value)
                    }
                  />
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">
                  &nbsp;元，打
                </Form.Item>
                <Form.Item rules={rules} className="goodsGroup__item">
                  <InputNumber
                    min={1}
                    value={this.state.discountMulti[1].ruleDis}
                    onChange={(value) =>
                      this.goodsDiscountMulti("type2", "ruleDis", value)
                    }
                  />
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">&nbsp;折。</Form.Item>
              </Form.Item>

              <Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={(e) =>
                      this.goodsDiscountMulti(
                        "type3",
                        "isChecked",
                        e.target.checked
                      )
                    }
                  >
                    滿額優惠，活動三。
                  </Checkbox>
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">滿&nbsp;</Form.Item>
                <Form.Item rules={rules} className="goodsGroup__item">
                  <InputNumber
                    min={1}
                    value={this.state.discountMulti[2].rule}
                    onChange={(value) =>
                      this.goodsDiscountMulti("type3", "rule", value)
                    }
                  />
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">
                  &nbsp;元，折
                </Form.Item>
                <Form.Item rules={rules} className="goodsGroup__item">
                  <InputNumber
                    min={1}
                    value={this.state.discountMulti[2].ruleDis}
                    onChange={(value) =>
                      this.goodsDiscountMulti("type3", "ruleDis", value)
                    }
                  />
                </Form.Item>
                <Form.Item className="goodsGroup__desTxt">&nbsp;元。</Form.Item>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => this.goodsSumCount()}>
                計算
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {this.state.isCounting && (
          <Col span={24}>
            <hr />
            <h1>計算結果</h1>
            {this.goodsTotalAmount} <br />
            {!!this.discountAmount.des && (
              <>
                活動後：
                {this.discountAmount.des} <br />
                {this.discountAmount.sum.toFixed(0)}
              </>
            )}
          </Col>
        )}
      </Row>
    );
  }
}
export default DiscountCompute;
