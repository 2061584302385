import React from "react";
import { Menu } from "antd";
import { RobotOutlined, AppstoreOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

class ComHeader extends React.Component {
  state = {
    current: "",
  };
  static getDerivedStateFromProps(props, state) {
    if (window.top.location !== window.self.location)
      window.top.location = window.self.location;
    return null;
  }
  handleClick = (e) => {
    //console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };
  render() {
    return (
      <Menu
        theme="dark"
        onClick={this.handleClick}
        selectedKeys={[this.state.current]}
        mode="horizontal"
      >
        <Menu.Item key="about">
          <RobotOutlined />
          <Link to="/About">關於我</Link>
        </Menu.Item>
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <AppstoreOutlined />
              小工具
            </span>
          }
        >
          <Menu.Item key="setting:1">
            <Link to="/">酒精換算</Link>
          </Menu.Item>
          <Menu.Item key="setting:2">
            <Link to="/mcs/tools/DiscountCompute">折價金額換算器</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <LinkOutlined />
              客戶案例
            </span>
          }
        >
          <Menu.Item key="link:1">
            <a href="/gujie296" target="_blank">曉鋪子</a>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}
export default ComHeader;
