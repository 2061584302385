import React from "react";
import { Carousel } from "antd";
import { Paintings } from "@constants/portfolio/shino/ConSummary";
const ComCarousel = (props) => {
  return (
    <Carousel afterChange={props.callback} effect="fade">
      {Paintings.map((painting, i) => {
        const { id, path } = painting;
        return (
          <div className="carousel__item">
            <img id={id} key={i} alt={`painting: ${id}`} src={path} />
          </div>
        );
      })}
    </Carousel>
  );
};
export default ComCarousel;
